/*--------------------------------------------------------------------- File Name: meanmenu.css ---------------------------------------------------------------------*/
a.meanmenu-reveal {
    display: none;
}

.mean-container .mean-bar {
    background: #000;
    float: left;
    min-height: 40px;
    padding: 5px 0;
    position: relative;
    width: 100%;
    z-index: 999;
    margin-top: 15px;
}

.mean-container a.meanmenu-reveal {
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    position: absolute;
    right: 12px;
    text-decoration: none;
    top: 12px;
    width: 26px;
}

.mean-container a.meanmenu-reveal span {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 0;
    display: block;
    height: 4px;
    margin-top: 3px;
    width: auto;
}

.mean-container a.meanmenu-reveal span:first-child {
    margin: 0
}

.mean-container .mean-nav {
    background: #ffffff none repeat scroll 0 0;
    float: left;
    margin-top: 44px;
    width: 100%;
}

.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
}

.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%;
}

.mean-container .mean-nav ul li a {
    border-bottom: 1px solid #ccc;
    color: #383838;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 1em 5%;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
}

.mean-container .mean-nav ul li a:hover {
    color: #38c8a8;
}

.mean-container .mean-nav ul li li a {
    width: 80%;
    padding: 1em 10%;
    border-top: 1px solid #ccc;
    border-top: 1px solid #ccc;
    opacity: 1;
    filter: alpha(opacity=1);
    text-shadow: none !important;
    visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
    width: 70%;
    padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
    width: 60%;
    padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
    width: 50%;
    padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
    background: #252525;
    background: rgba(255, 255, 255, 0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
    background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
    border: medium none;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    margin-top: 1px;
    padding: 12px 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -1px;
    width: 17px;
    z-index: 2;
    font-size: 18px !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
    color: #ffffff;
}

.mean-container .mean-push {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    clear: both;
}

.mean-nav .wrapper {
    width: 100%;
    padding: 0;
    margin: 0;
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.mean-remove {
    display: none !important;
}

.mean-nav span {
    display: none;
}

.mean-container .mean-nav ul li a.mean-expand:hover,
.mean-container .mean-nav ul li a.mean-expand:focus {
    background: #0f69c6;
    color: #ffffff;
}

.mean-container .mean-nav ul li a:hover {
    color: #ef4259;
}